import { css } from '@emotion/react';

export const title = css`
    color: #ffb300;
    font-weight: 900;
    font-size: 2.4rem;
    margin-bottom: 4rem;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: -0.15em;
        left: 0.4em;
        width: 1.2em;
        height: 0.3em;
        background-image: radial-gradient(circle, #ffb300 35%, transparent 0);
        background-size: 0.3em 0.3em;
        background-repeat: repeat-x;
        background-position: 0 0;
    }
`;

export const policy = css`
    h2 {
        margin: 3rem 0 1rem;
    }

    p {
        margin: 1rem 0;
    }

    ul {
        margin-left: 1rem;
    }

    li {
        margin-bottom: 0.5rem;
    }
`;
